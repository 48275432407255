.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background: #5e5df0;
  border-radius: 999px;
  box-shadow: #5e5df0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  outline: 0 solid transparent;
  padding: 8px 18px;
  word-break: break-word;
  border: 0;
}

#deletePlayerButton {
  background: #ff6161;
}

#addNewPlayerButton {
}

#rollPigButton {
}

#commitPointsButton {
}

.pigContainer img {
  width: 150px;
}

.playerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.playerElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid;
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  width: 150px;
}

.ID_currentPlayer {
  border: 4px solid green;
}

.playerMainInside p {
  margin: 15px;
}

.ThrowPointsText {
  font-size: 24px;
  color: yellow;
}

.RoundPointsText {
  font-size: 20px;
  color: lightgreen;
}
